<template>
  <div class="mt-3">
    <h1 class="mb-3">
      {{ $t('cntct.t') }}
    </h1>
    <b-row>
      <b-col class="mt-2 justify-content-center" sm="12" md="6">
        <div class="card text-center">
          <div class="card-header"><b-icon-at v-bind="iT" /> {{ $t('cntct.emailh') }}</div>
          <div class="card-body">
            <h5 class="card-title">{{ $t('cntct.emailt') }}</h5>
            <p class="card-text">{{ $t('cntct.emailm') }}</p>
            <b-button :href="`mailto:`+mail" variant="primary">
              {{ mail }}
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col class="mt-2 justify-content-center" sm="12" md="6">
        <div class="card text-center">
          <div class="card-header"><b-icon-phone v-bind="iT" /> {{ $t('cntct.phoneh') }}</div>
          <div class="card-body">
            <h5 class="card-title">{{ $t('cntct.phonet') }}</h5>
            <p class="card-text">{{ $t('cntct.phonem') }}</p>
            <b-button :href="`tel:`+phone" variant="primary">
              {{ phone }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BIconAt, BIconPhone } from 'bootstrap-vue';

export default {
  data() {
    return {
      phone: '+49 163 773 5 373',
      mail: 'contact@anures.com',
    };
  },
  computed: {
    iT() {
      return this.$store.getters.iT;
    },
  },
  components: {
    BIconAt,
    BIconPhone,
  }
}
</script>